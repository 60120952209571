// src/store.js
import { configureStore } from "@reduxjs/toolkit";
import dashboardReducer from "../redux/reducers/dashboardReducer";
const store = configureStore({
    reducer: {
      dashboard: dashboardReducer,
    },
  });

export default store;
