import React from "react";
import { Link, useLocation } from "react-router-dom";
import { profileDetail } from "../../services/data/NavigationData";
import { corporateLinks } from "../../services/data/NavigationData";
import { AnimatePresence, motion } from "framer-motion";
import { Twirl as Hamburger } from "hamburger-react";
import { logo } from "../../constants/images";

const CorporateSidebar = ({ isOpen, setOpen }) => {
  let e = useLocation();
  // console.log(e);
  return (
    <>
      <>
        <section className=" xl:block w-[212px] xl:w-[212px] min-h-[100vh] hidden  z-50 bg-white  navigation-shadow  ">
          <Link
            onClick={() => setOpen(false)}
            href="/dashboard"
            className="ml-5 "
          >
            <div className="hidden lg:block mb-[107px] w-[150px] mt-[14px] ml-[33px]">
              <img src={logo.mmbLogo} alt="" className="w-[100%]" />
            </div>
            <div className="lg:hidden fixed left-0">
              <Hamburger
                color="#0b211a"
                size={20}
                label="Show menu"
                toggled={isOpen}
                toggle={setOpen}
              />
            </div>
          </Link>
          <nav className="overflow-y-auto h-screen  transition-all ease-in scrollbar-thin hover:scrollbar-thumb-primary hover:scrollbar-track-gray-200 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
            <div className="ml-[19px] mr-[13px]">
              <div className="">
                {e.pathname === "/corporate-admin-1" && (
                  <ul className="flex flex-col gap-[5px] [&>*:first-child]:bg-[#47A7FD]  [&>*:first-child]:rounded-md  [&>*:first-child]:text-[#FFF]">
                    {corporateLinks
                      .filter(
                        (item) =>
                          item.label === "Dashboard" ||
                          item.label === "Help Center" ||
                          item.label === "Settings"
                      )
                      .map((link, index) => {
                        return (
                          <li key={link.label + index}>
                            <Link
                              href={link.to}
                              target={link.external ? "_blank" : "_self"}
                              className={
                                "flex w-full opacity-60 hover:opacity-100  p-[10px] border-l-[3px] transition-all border-transparent hover:border-secondary hover:bg-[#47A7FD] hover:rounded-md hover:text-[#FFF] items-center space-x-2"
                              }
                            >
                              <img src={link.icon} alt="" />

                              <span className="text-[#333] hover:text-[#FFF] font-Poppins font-normal text-[10px]">
                                {link.label}
                              </span>
                            </Link>
                          </li>
                        );
                      })}
                  </ul>
                )}
                {e.pathname === "/admin-landing-page" && (
                  <ul className="flex flex-col gap-[5px] [&>*:first-child]:bg-[#47A7FD]  [&>*:first-child]:rounded-md  [&>*:first-child]:text-[#FFF]">
                    {corporateLinks
                      .filter((item) => item.label === "Services")
                      .map((link, index) => {
                        return (
                          <li key={link.label + index}>
                            <Link
                              href={link.to}
                              target={link.external ? "_blank" : "_self"}
                              className={
                                "flex w-full opacity-60 hover:opacity-100  p-[10px] border-l-[3px] transition-all border-transparent hover:border-secondary hover:bg-[#47A7FD] hover:rounded-md hover:text-[#FFF] items-center space-x-2"
                              }
                            >
                              <img src={link.icon} alt="" />

                              <span className="text-[#333] hover:text-[#FFF] font-Poppins font-normal text-[10px]">
                                {link.label}
                              </span>
                            </Link>
                          </li>
                        );
                      })}
                  </ul>
                )}
              </div>
            </div>
          </nav>
          {(e.pathname === "/corporate-admin-1") |
          (e.pathname === "/admin-landing-page") ? (
            ""
          ) : (
            <div className="flex justify-center  w-[188px] p-[10px] ml-[14px] mt-[520px] text-black bg-[#E5EFFB] rounded-lg ">
              <div className="w-[35px] pr-2">
                <img src={profileDetail.image} alt="" className="w-[100%]" />
              </div>
              <div className="text-black lg:w-[113px]">
                <p className="font-bold text-[8px]">{profileDetail.name}</p>
                <p className="font-medium text-[8px]">{profileDetail.title}</p>
              </div>
              <div className="w-[10px]">
                <button onClick={() => alert("loading...")}>
                  {" "}
                  <img src={profileDetail.logo} alt="" className="w-[100%]" />
                </button>
              </div>
            </div>
          )}
        </section>
        <AnimatePresence initial={false}>
          {isOpen && (
            <>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { type: "just" } }}
                exit={{
                  opacity: 0,
                  transition: { type: "just", delay: 0.1 },
                }}
                className="fixed top-0 left-0 right-0 bottom-0 bg-[#545f7d80]"
                onClick={() => setOpen(false)}
              />
              <motion.section
                initial={{ x: "-100%", opacity: 0 }}
                animate={{ x: 0, opacity: 1, transition: { type: "just" } }}
                exit={{
                  x: -100,
                  opacity: 0,
                  transition: { type: "just", delay: 0.1 },
                }}
                className="w-[283px] fixed z-40 left-0 overflow-scroll top-0 h-screen bg-white py-10 navigation-shadow "
              >
                <Link
                  onClick={() => setOpen(false)}
                  href="/dashboard"
                  className="ml-4 flex justify-between"
                >
                  <div className="mt-3 mb-10">
                    <img src={logo.mmbLogo} alt="" />
                  </div>
                  <div className="lg:hidden ">
                    <Hamburger
                      color="#0b211a"
                      size={20}
                      label="Show menu"
                      toggled={isOpen}
                      toggle={setOpen}
                    />
                  </div>
                </Link>
                <nav className="overflow-y-auto h-screen  transition-all ease-in scrollbar-thin hover:scrollbar-thumb-primary hover:scrollbar-track-gray-200 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
                  <div className="ml-[19px] mr-[13px]">
                    <div className="">
                      <ul className="flex flex-col gap-[5px] ">
                        {corporateLinks
                          .filter(
                            (item) =>
                              item.label === "Dashboard" ||
                              item.label === "Help Center" ||
                              item.label === "Settings"
                          )
                          .map((link, index) => {
                            return (
                              <li key={link.label + index}>
                                <Link
                                  href={link.to}
                                  target={link.external ? "_blank" : "_self"}
                                  className={
                                    "flex w-full opacity-60 hover:opacity-100  p-[10px] border-l-[3px] transition-all border-transparent hover:border-secondary hover:bg-[#47A7FD] hover:rounded-md hover:text-[#FFF] items-center space-x-2"
                                  }
                                >
                                  <img src={link.icon} alt="" />

                                  <span className="text-[#333] hover:text-[#FFF] font-Poppins font-normal text-[13px]">
                                    {link.label}
                                  </span>
                                </Link>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </nav>
                {e.pathname === "/corporate-admin-1" ? (
                  ""
                ) : (
                  <div className="flex justify-between  w-[188px] p-[10px] ml-[14px]  text-black bg-[#E5EFFB] rounded-lg ">
                    <div className="w-[35px]">
                      <img
                        src={profileDetail.image}
                        alt=""
                        className="w-[100%]"
                      />
                    </div>
                    <div className="text-black lg:w-[113px]">
                      <p className="font-bold text-[8px]">
                        {profileDetail.name}
                      </p>
                      <p className="font-medium text-[8px]">
                        {profileDetail.title}
                      </p>
                    </div>
                    <div className="w-[10px]">
                      <button onClick={() => alert("loading...")}>
                        {" "}
                        <img
                          src={profileDetail.logo}
                          alt=""
                          className="w-[100%]"
                        />
                      </button>
                    </div>
                  </div>
                )}
              </motion.section>
            </>
          )}
        </AnimatePresence>
      </>
    </>
  );
};

export default CorporateSidebar;
