import { minibar } from "../../constants/icons";
import { Link } from 'react-router-dom';

export const MiniSidebarData = [
    {
        href: '/',
        icon:  <img src={minibar.home} alt="Home" />
        ,
    },
    {
        href: 'javascript:void(0)',
        icon: <img src = {minibar.user} alt="Profile" />
        ,
    },
    {
        href: 'javascript:void(0)',
        icon: <img src = {minibar.Dialogues} alt="Message" />

        ,
    },
    {
        href: 'javascript:void(0)',
        icon: <img src = {minibar.Loupe} alt="Search" />,
    },
    {
        href: 'javascript:void(0)',
        icon: <img src = {minibar.Calendar} alt="Calender" />,
    },
    {
        href: 'javascript:void(0)',
        icon: <img src = {minibar.Settings} alt="Settings" />,
    }
]