import React, {useState, useEffect} from "react";
import {minibar} from '../../constants/icons';
import { integrations, referall  } from '../../services/data/userprofileData';
import AreaCharts from '../../components/charts/AreaChat';
import Roundchart from '../../components/charts/RoundChat';
import axiosInstance from '../../services/axiosconfig/AxiosAuth'
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";



const ProfileCards =  () => {
    
    const [refData, setRefData] = useState([]);  //initailize the state
    const [inviteData, setInviteData] = useState([]);
    const [loading, setLoading] = useState(true);

    const [showAllItems, setShowAllItems] = useState(false);

    const user_id = useParams(); //get each user id

    const toggleShowAllItems = () => {
        setShowAllItems(!showAllItems);
      };

 

 // get user invite code with user id
    useEffect(() => {
        const fetchRefData = async () => {
          try {
            const response = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/api/v1/admin/get-invite-code/${user_id.id}`);
            setRefData(response.data);
            setLoading(false);
          } catch (error) {
            setLoading(false);
            console.error("Fetching error", error);
          }
        };
    
        fetchRefData();
      }, [user_id.id]);

      //get referalls with invite code
    
      useEffect(() => {
        if (refData) {
          const inviteCode = refData?.data?.inviteCode;
    
          if (inviteCode) {
            const apiUrl = `${process.env.REACT_APP_API_URL}/api/v1/admin/user-invite/${inviteCode}`;
            axiosInstance.get(apiUrl)
              .then((response) => {
                setInviteData(response.data);
                setLoading(false);
                console.log('Success', response.data);
              })
              .catch((error) => {
                setLoading(false);
                console.error("Fetching error", error);
              });
          }
        }
      }, [refData]);

      const referalls = inviteData?.data?.data;

      const number_of_referal = refData?.data?.inviteCount;
     

      const replacementArray = [
        { id: 2, title: number_of_referal },
      
      ];
    
    


    

  
    return(
    <section className="py-[11px] ">
        <div className="flex-col-reverse">
        <div className=" mx-auto px-4 md:px-8">
            <ul className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3 mb-8">
                {
                    integrations.map((item,idx) => (
                        <li className="border drop-shadow-lg rounded-3xl w-[100%] h-[100px] bg-white" key={idx}>
                            <div className="flex items-center space-x-4">
                                <div className="flex-shrink-0 my-4 mx-4 p-2 bg-bgicon bg-auto rounded-lg">
                                    {item.icon} 
                                </div>
                                <div className="flex-1 min-w-0">
                                    <p className=" text-textcard text-lg font-Roboto font-bold leading-5 py-2 ">
                                    {item.title}
                                    </p>
                                    <p className="font-Roboto font-normal text-[12px] leading-5  text-[#7C8DB5] py-1">
                                    {item.desc}
                                    </p>
                                </div>
                            
                            </div>
                        </li>
                    ))
                }
            </ul>
        </div>
        <div className="mx-auto w-[95%] h-[400px] bg-white py-4 px-4 md:px-8 rounded-3xl ">
            <div className='flex'>
                <div className='w-[100%]'>
                <AreaCharts/>
                </div>
                <div className=''>
                <Roundchart/>
                </div>
            </div>
        </div>
        <div className="mx-[2rem] {`max-w-${showAllItems ? 'full' : 'md'} overflow-hidden w-[50%] h-auto bg-[#FCFCFD]  md:px-8 rounded-3xl py-2 space-y-4 mt-9 pl-6">               
            <div className="flex py-4 ">
                <div className="flex-1">
                    <img className='w-2' src={minibar.dot}/>
                </div>
                    <div className="flex-0 font-Roboto text-[18px] font-medium leading-3 pr-[18rem] text-textcard tracking-normal">
                        Recent Referrals
                   </div>
                </div>
                <div className="flex py-3">
                <div className="flex-1 font-Roboto text-[20px] font-bold leading-3 text-textcard">
                    {/* ₦ 4,000 */}
                </div>
                <div className="grid grid-cols-5 pl-30">
                <div className='font-Roboto text-[14px] text-[#7C8DB5] font-normal leading-4 '>This Month</div>
                <div className='px-2 py-1'><img src={minibar.arrowDown}/></div>
                </div>
                  
                </div>
                
                   {
                    referalls?.slice(0, showAllItems ? referalls.length : 2).map((item) => (
                            <div className="flex items-center rounded-xl px-2 py-6 w-[434px] h-[68px] bg-white o ">
                                <div className="w-11 h-11 shrink-0 grow-0 rounded-full px-[13.5px] py-3 bg-bgicon ">
                                    <div className='w-[17.69px]'><img src={minibar.box}/></div>
                                </div>
                                  {}
                                <div className="flex-1 min-w-0">
                                    <p className="text-Montserrat text-[14px] font-black py-2 px-4 text-[#0F3F62] leading-4 ">
                                    {item.first_name}  {item.last_name}
                                    </p>
                                    <div className='flex text-[#7C8DB5] px-4'>
                                        <div className=''>
                                    <p className="text-[12px] ">
                                    {item.first_name}  {item.last_name} used your referal code
                                    </p>
                                    </div>

                                    <div className='w-1/6'>
                                    <p className="text-[16px] pl-[2rem] font-roboto font-bold leading-4 text-textcard whitespace-nowrap ">
                                    + ₦20
                                    </p>
                                    </div>

                                    <div className='w-1/3'>
                                    <p className=" w-11 h-11 pl-[2rem]">
                                    <img src = {minibar.graph} />
                                    </p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                    ))
                }
                <div className='text-center'>
                    <p className='font-Roboto text-[14px] leading-4 font-normal text-[#25C0FF] underline underline-offset-8'  onClick={toggleShowAllItems}>
                    {showAllItems ? 'See Less History' : 'See Full History'}</p>
                </div>
        </div>
        </div>
    </section>
    )
}

export default ProfileCards;