import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const Circularchart = ({ percentage, pathColor }) => {
  return (
    <div className="lg:w-[76px] max-[650px]:w-[76px] ">
      <CircularProgressbar
        value={percentage}
        text={`${percentage}%`}
        styles={buildStyles({
          // Rotation of path and trail, in number of turns (0-1)
          rotation: 0.25,

          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
          strokeLinecap: "butt",
          textSize: "20px",
          textWeight:"bold",

          // How long animation takes to go from one percentage to another, in seconds

          textColor: "#25282B",
          trailColor: '#F2F2F2',
          pathColor,
        
        })}
      />
    </div>
  );
};

export default Circularchart ;
