import React, { PureComponent } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const data = [
  {
    name: 'April 05',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'April 06',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'April 07',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'April 08',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'April 09',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'April 10',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'April 11',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const AreaCharts = () => {
 
    return (
        <div>
        <div className="pt-4 pb-5 px-4 flex justify-between items-center ">
        <p className="text-lg font-bold ">Report Referral</p>
      
      </div>
      <div style={{ width: '95%', height: 300 }}>
        <ResponsiveContainer>
          <AreaChart
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Area type="monotone" dataKey="uv" stroke="#8884d8" fill="#47A7FD" />
          </AreaChart>
        </ResponsiveContainer>
      </div>
      </div>
    );
}

export default AreaCharts;
