import {minibar} from '../../constants/icons';
export const UserProfileCards = [
    {
        title: 'Name',
        details: 'Fola Femi'
    },
    {
        title: 'Date Of Birth',
        details: 'Fola Femi'
    },
    {
        title: 'Referal Balance',
        details: 'Fola Femi'
    },
    {
        title: 'Mobile Number',
        details: 'Fola Femi'
    },
    {
        title: 'Email Address',
        details: 'Fola Femi'
    },
    {
        title: 'Address',
        details: 'Fola Femi'
    },
    {
        title: 'City',
        details: 'Fola Femi'
    },
    {
        title: 'Country',
        details: 'Fola Femi'
    },
];

export const integrations = [
    {
        title: "5",
        desc: "subscriptions",
        icon: <img src = {minibar.bank}  alt='bank'/>

    }, {
        title: "₦90,000",
        desc: "All Time Spent",
        icon: <img src = {minibar.savings} alt='time' />
           

    }, {
        title: "3",
        desc: "Number of Referalls",
        icon: <img src = {minibar.wallet} alt='referals' />

    },
];

export const referall = [
    {
        title: "Adeyemi Taiwo",
        desc: "Adeyemi Taiwo used your Referral Code",
        icon: <img src = {minibar.box} />

    }, {
        title: "Sannu Ijuo",
        desc: "Sannu Ijuo used your Referral Code",
        icon: <img src = {minibar.box} />
    }
]
