import { React } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { images } from "../../../constants/icons";

const Index = () => {
  const searchTitle = "Type here..."
  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      searchValue: "",
    },
  });

  const {
    formState: { errors },
  } = methods;

  /* Handle submit */
  const onSubmit = async (payload, event) => {
    event?.stopPropagation();
  };

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="mr-[12px] ">
          <div className="flex border-solid   bg-white  rounded-lg border-white border-2  p-2 items-center ">
            <input
              name="search"
              type="search"
              onError={errors}
              placeholder={searchTitle}
              className="pl-[10px] bg-transparent pr-[10px] py-[5px] !w-[288px] font-Poppins text-[#DCDCDC] text-[10px] rounded-lg placeholder-primary::placeholder hover:bg-white  cursor-pointer appearance-none focus:outline-none "
            />

            <div className="w-[18px]">
              <img src={images.searchIcon} alt="" className="w-[100%]" />
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default Index;
