import React from "react";
import UserWidget from "../../components/corporatedashboard/UserWidget";
import IncomeCard from "../../components/corporatedashboard/IncomeCard";
import Corporatewidget from "../../components/global/CorporateWidget";
import {userDataIcons} from "../../constants/icons"

const UsersContainer = () => {
  return (
    <div className="flex justify-between w-[100%] h-[188px] mt-[73px] mb-[47px]">
      <div>
        <Corporatewidget img={userDataIcons.workerIcon} />
      </div>
      <div>
        <Corporatewidget img={userDataIcons.clientIcon} />
      </div>

      <div>
        <UserWidget />
      </div>

      <div>
        <IncomeCard />
      </div>
    </div>
  );
};

export default UsersContainer;
