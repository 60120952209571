import React from "react";


const ShowingUserData = ({rows,handleChangePage, handleChangeRowsPerPage, rowsPerPage, page}) => {

  return (
    <div className="flex justify-between mt-[53.09px]">
      <div>
        {/* <p>Showing 10 from {rows.length ? rows.length : 0} data</p> */}
      </div>
      <div className="flex justify-between w-[40%]">
        {/* <div className="border border-[#47A7FD] w-[165.73px] rounded-lg p-3 flex">
          <span>
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.5208 18.2105L6.38245 12.4185L12.5208 6.62646"
                stroke="#47A7FD"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M19.6821 18.2105L13.5438 12.4185L19.6821 6.62646"
                stroke="#47A7FD"
                stroke-opacity="0.5"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <span className="">Previous</span>
        </div> */}
       
        {/* <div className="border border-[#47A7FD] w-[117.65px] rounded-lg flex p-3">
          <span>Next</span>
          <svg
            width="26"
            height="25"
            viewBox="0 0 26 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.2623 18.2105L19.4006 12.4185L13.2623 6.62646"
              stroke="#47A7FD"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6.10095 18.2105L12.2393 12.4185L6.10095 6.62646"
              stroke="#47A7FD"
              stroke-opacity="0.5"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div> */}
      </div>

<tfoot>
          
          </tfoot>
    </div>
  );
};

export default ShowingUserData;
