import { images } from "../../constants/icons";
import { withdrawalsImages } from "../../constants/images";

export const withdrawalHeader = [
  {
    title: "Name",
  },
  { title: "Date Request" },
  { title: "Date Approved" },
  { title: "Date Disbursed" },

  { title: "Actions" },
];

export const withdrawalData = [
  {
    id: 1,
    name: "Jane Cooper",
    image: withdrawalsImages.janeImg,
    job: "Health Worker",
    dateRequested: "10/11/2023",
    dateApproved: "15/05/2023",
    dateDisbursed: "15/05/2023",
    actions: images.horizontalIcon,
  },
  {
    id: 2,
    name: "Wade Warren",
    image: withdrawalsImages.wadeImg,
    job: "Corporate",
    dateRequested: "10/11/2023",
    dateApproved: "15/05/2023",
    dateDisbursed: "15/05/2023",
    actions: images.horizontalIcon,
  },
  {
    id: 3,
    name: "Eleanor Pena",
    image: withdrawalsImages.penaImg,
    job: "Health Worker",
    dateRequested: "10/11/2023",
    dateApproved: "15/05/2023",
    dateDisbursed: "15/05/2023",
    actions: images.horizontalIcon,
  },
  {
    id: 4,
    name: "Leslie Alexander",
    image: withdrawalsImages.leslisImg,
    job: "Health Worker",
    dateRequested: "10/11/2023",
    dateApproved: "15/05/2023",
    dateDisbursed: "15/05/2023",
    actions: images.horizontalIcon,
  },
  {
    id: 5,
    name: "Guy Hawkins",
    image: withdrawalsImages.guyImg,
    job: "Corporate",
    dateRequested: "10/11/2023",
    dateApproved: "15/05/2023",
    dateDisbursed: "15/05/2023",
    actions: images.horizontalIcon,
  },
];
