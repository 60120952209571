import React, {useState, useEffect} from 'react'
import UserSidebar from "../../components/global/ClientSidebar";
import CorporateHeader from "../../components/corporatedashboard/CorporateHeader";
import axiosInstance from '../../services/axiosconfig/AxiosAuth';


const HealthInsight = () => {
    const [insight, setData] = useState("");
    const [loading, setLoading] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [formData, setFormData] = useState({
        bloodPressureSystolicLowerLimit: null,
        bloodPressureSystolicUpperLimit:null,
        bloodPressureDiastolicLowerLimit:null,
        bloodPressureDiastolicUpperLimit :null,
        pulseLowerLimit:null,
        pulseUpperLimit:null,
        temperatureLowerLimit:null,
        temperatureUpperLimit:null,
        respiratoryRateLowerLimit:null,
        respiratoryRateUpperLimit:null,
        bloodGlucoseFastingLowerLimit:null,
        bloodGlucoseFastingUpperLimit:null,
        bloodGlucoseNonFastingLowerLimit:null,
        bloodGlucoseNonFastingUpperLimit:null,
        bloodOxygenLowerLimit:null,
        bloodOxygenUpperLimit:null,
        underweight:null,
        healthyWeight:null,
        overweight:null,
        obesity:null,
      });

    const handleChange = (e) => {
        const { name, value } = e.target;
            // Convert the value to a number if it's not empty
    const numericValue = value !== '' ? parseFloat(value) : null;

        setFormData({
          ...formData,
          [name]: numericValue,
        });
      };

      useEffect(() => {
        const apiUrl = `${process.env.REACT_APP_API_URL}/`
        axiosInstance.get(`${apiUrl}api/v1/admin/healthInsight-setup` )
              .then((response) => {
                
                setData(response.data)
                setFormData(response.data.data.data[0]); 
              
            })
            
              .catch((error) => {
                console.log("error fetching data", error)
              })
      }, [])

      const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
      
        axiosInstance.put(`${process.env.REACT_APP_API_URL}/api/v1/admin/healthInsight-setup/2`, formData,)
          .then((response) => {
            if (response.status === 200){
                setShowSuccessModal(true);
                setTimeout(() => {
                    setShowSuccessModal(false);
                
                  }, 3000);
            setLoading(false);
                }
          })
          .catch((error) => {
            // Handle the error
            console.error('Error:', error);
            setLoading(false);
          });
      };
      

    const [isOpen, setOpen] = useState(false);
  return (
        <div className="flex justify-between bg-[#F9F9F9]  font-Montserrat">
          <UserSidebar isOpen={isOpen} setOpen={setOpen} statusStyle={'mt-[135vh]'}/>
          <div className="w-[calc(100%-212px)] xl:w-[calc(100%-212px)]  pl-[36px] pt-[22.5px] pr-[36px]">
        <CorporateHeader isOpen={isOpen} setOpen={setOpen} />

        <div className="text-12 py-6">
          <p className='font-Robotto '>Settings</p>
          </div>
          <div className="w-[100%] h-auto bg-[#FFFFFF] rounded-lg p-6 ">
          <form onSubmit={handleSubmit}>
             <h1 className='text-black text-sm font-Poppins font-semibold'>Health Insight Setup</h1> 
             <div className='flex'>
                <div className='flex 1 text-black text-[13px] font-bold font-Poppins px-5 py-10'>
                    <div className='flex flex-col'>
                    <p>Metric</p>
                    <p className='py-4'>Blood Pressure (Systolic)</p>
                    <p className='py-4'>Blood Pressure (Diastolic)</p>
                    <p className='py-4'>Pulse</p>
                    <p className='py-4'>Temperature</p>
                    <p className='py-4'>Respiratory Rate</p>
                    <p className='py-4'>Blood Glucose (Fasting)</p>
                    <p className='py-4'>Blood Glucose (Non-Fasting)</p>
                    <p className='py-4'>Blood Oxygen</p>
                    </div>
                </div>
                <div className='flex 1 text-black text-[13px] font-bold font-Poppins p-10'>
                <div className='flex flex-col'>
                    <p>Lower Limit</p>
                    <p className="py-[0.18rem]">
                    <input
                        type= "number"
                        name= "bloodPressureSystolicLowerLimit"
                        value={formData.bloodPressureSystolicLowerLimit}
                        onChange= {handleChange}
                        className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-[#47A7FD] shadow-sm rounded-lg"
                        />
                    </p>
                    <p className="py-[0.18rem]">
                    <input
                        type= "number"
                        name= "bloodPressureDiastolicLowerLimit"
                        value= {formData.bloodPressureDiastolicLowerLimit}
                        onChange= {handleChange}
                        className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-[#47A7FD] shadow-sm rounded-lg"
                        />
                    </p>
                    <p className="py-[0.18rem]">
                    <input
                        type= "number"
                        name= "pulseLowerLimit"
                        value= {formData.pulseLowerLimit}
                        onChange= {handleChange}
                        className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-[#47A7FD] shadow-sm rounded-lg"
                        />
                    </p>
                    <p className="py-[0.18rem]">
                    <input
                        type= "number"
                        name= "temperatureLowerLimit"
                        value= {formData.temperatureLowerLimit}
                        onChange= {handleChange}
                        className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-[#47A7FD] shadow-sm rounded-lg"
                        />
                    </p>
                    <p className="py-[0.18rem]">
                    <input
                        type= "number"
                        name= "respiratoryRateLowerLimit"
                        value= {formData.respiratoryRateLowerLimit}
                        onChange= {handleChange}
                        className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-[#47A7FD] shadow-sm rounded-lg"
                        />
                    </p>
                    <p className="py-[0.18rem]">
                    <input
                        type= "number"
                        name= "bloodGlucoseFastingLowerLimit"
                        value= {formData.bloodGlucoseFastingLowerLimit}
                        onChange= {handleChange}
                        className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-[#47A7FD] shadow-sm rounded-lg"
                        />
                    </p>
                    <p className="py-[0.18rem]">
                    <input
                        type= "number"
                        name= "bloodGlucoseNonFastingLowerLimit"
                        value= {formData.bloodGlucoseNonFastingLowerLimit}
                        onChange= {handleChange}
                        className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-[#47A7FD] shadow-sm rounded-lg"
                        />
                    </p>
                    <p className="py-[0.18rem]">
                    <input
                        type= "number"
                        name= "bloodOxygenLowerLimit"
                        value= {formData.bloodOxygenLowerLimit}
                        onChange= {handleChange}
                        className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-[#47A7FD] shadow-sm rounded-lg"
                        />
                    </p>
                
              
                  
                    </div>
                </div>
                <div className='flex 1 text-black text-[13px] font-bold font-Poppins p-10'>
                <div className='flex flex-col'>
                    <p>upper Limit</p>
                    <p className="py-[0.18rem]">
                    <input
                        type= "number"
                        name= "bloodPressureSystolicUpperLimit"
                        value= {formData.bloodPressureSystolicUpperLimit}
                        onChange= {handleChange}
                        className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-[#47A7FD] shadow-sm rounded-lg"
                        />
                    </p>
                    <p className="py-[0.18rem]">
                    <input
                        type= "number"
                        name= "bloodPressureDiastolicUpperLimit"
                        value= {formData.bloodPressureDiastolicUpperLimit}
                        onChange= {handleChange}
                        className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-[#47A7FD] shadow-sm rounded-lg"
                        />
                    </p>
                    <p className="py-[0.18rem]">
                    <input
                        type= "number"
                        name= "pulseUpperLimit"
                        value= {formData.pulseUpperLimit}
                        onChange= {handleChange}
                        className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-[#47A7FD] shadow-sm rounded-lg"
                        />
                    </p>
                    <p className="py-[0.18rem]">
                    <input
                        type= "number"
                        name= "temperatureUpperLimit"
                        value= {formData.temperatureUpperLimit}
                        onChange= {handleChange}
                        className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-[#47A7FD] shadow-sm rounded-lg"
                        />
                    </p>
                    <p className="py-[0.18rem]">
                    <input
                        type= "number"
                        name= "respiratoryRateUpperLimit"
                        value= {formData.respiratoryRateUpperLimit}
                        onChange= {handleChange}
                        className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-[#47A7FD] shadow-sm rounded-lg"
                        />
                    </p>
                    <p className="py-[0.18rem]">
                    <input
                        type= "number"
                        name= "bloodGlucoseFastingUpperLimit"
                        value= {formData.bloodGlucoseFastingUpperLimit}
                        onChange= {handleChange}
                        className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-[#47A7FD] shadow-sm rounded-lg"
                        />
                    </p>
                   
                    <p className="py-[0.18rem]">
                    <input
                        type= "number"
                        name= "bloodOxygenUpperLimit"
                        value= {formData.bloodOxygenUpperLimit}
                        onChange= {handleChange}
                        className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-[#47A7FD] shadow-sm rounded-lg"
                        />
                    </p>
                    <p className="py-[0.18rem]">
                    <input
                        type= "number"
                        name= "bloodOxygenUpperLimit"
                        value= {formData.bloodOxygenUpperLimit}
                        onChange= {handleChange}
                        className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-[#47A7FD] shadow-sm rounded-lg"
                        />
                    </p>   
                    </div>
                </div>
                <div className='flex 1 text-black text-[13px]  p-10'>
                <div className='flex flex-col'>
                <p className='text-black font-Poppins font-bold'>Unit</p>
                <p className='py-4 font-Montserrat'>mmHg</p>
                <p className='py-4 font-Montserrat'>mmHg</p>
                <p className='py-4 font-Montserrat'>beats/min</p>
                <p className='py-4 font-Montserrat'>°C</p>
                <p className='py-4 font-Montserrat'>breaths/min</p>
                <p className='py-4 font-Montserrat'>mg/dl</p>
                <p className='py-4 font-Montserrat'>mmol/L</p>
                <p className='py-4 font-Montserrat'>mg/dl</p>
                <p className='py-4 font-Montserrat'>%</p>
                </div>
                </div>
               

             </div>

             <div className='border-2 border-transparent p-2' >
                Body Mass Index
                <div class="flex mb-4">
                <div class="w-1/5">
                <div className='flex 1 text-black text-[13px] font-bold font-Poppins px-5 py-10'>
                    <div className='flex flex-col'>
                    <p className="py-[0.18rem]">
                    <input
                        type= "number"
                        name= "underweight"
                        value= {formData.underweight}
                        onChange= {handleChange}
                        className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-[#47A7FD] shadow-sm rounded-lg"
                        />
                    </p>
                    <p className="py-[0.18rem]">
                    <input
                        type= "number"
                        name= "healthyWeight"
                        value= {formData.healthyWeight}
                        onChange= {handleChange}
                        className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-[#47A7FD] shadow-sm rounded-lg"
                        />
                    </p>
                    <p className="py-[0.18rem]">
                    <input
                        type= "number"
                        name= "overweight"
                        value= {formData.overweight}
                        onChange= {handleChange}
                        className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-[#47A7FD] shadow-sm rounded-lg"
                        />
                    </p>
                      <p className="py-[0.18rem]">
                    <input
                        type= "number"
                        name= "obesity"
                        value= {formData.obesity}
                        onChange= {handleChange}
                        className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-[#47A7FD] shadow-sm rounded-lg"
                        />
                    </p>
                  
                    </div>
                </div>
                </div>
                {/* <div class="w-1/5">
                <div className='flex 1 text-black text-[13px] font-bold font-Poppins px-5 py-10'>
                    <div className='flex flex-col'>
                    <p className="py-[0.18rem]">
                    <input
                        type= "text"
                        name= "UnderWeightz"
                        value= "UnderWeight"
                        onChange= {handleChange}
                        className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-[#47A7FD] shadow-sm rounded-lg"
                        />
                    </p>
                    <p className="py-[0.18rem]">
                    <input
                        type= "text"
                        name= "HealthyWeights"
                        value= "HealthyWeight"
                        onChange= {handleChange}
                        className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-[#47A7FD] shadow-sm rounded-lg"
                        />
                    </p>
                    <p className="py-[0.18rem]">
                    <input
                        type= "text"
                        name= "OverWeights"
                        value= "OverWeight"
                        onChange= {handleChange}
                        className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-[#47A7FD] shadow-sm rounded-lg"
                        />
                    </p>
                       <p className="py-[0.18rem]">
                    <input
                        type= "text"
                        name= "Obesitys"
                        value= "Obesity"
                        onChange= {handleChange}
                        className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-[#47A7FD] shadow-sm rounded-lg"
                        />
                    </p>
                  
                    </div>
                </div>
                </div> */}
              
                {/* <div class="w-1/5 bg-gray-500 h-12"></div> */}
                </div>
             </div>
             {loading ? 
                <button type="submit" className="w-[150px] h-[46px] leading-6 mx-[15rem] text-white bg-bgloginbutton hover:bg-bg-bgloginbutton/90 
                    rounded-3xl text-[16px] leading-16 font-semibold font-Poppins px-14 py-2.5 text-center inline-flex items-center 
                    mr-2 mb-2" disabled={loading}>  
                      
                        <svg aria-hidden="true" className="inline w-8 h-8  text-gray-200 animate-spin dark:text-gray-600 fill-red-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg> 
                  </button>
                        : 
                        <button type="submit" className="w-[150px] h-[46px] leading-6 mx-[15rem] text-white bg-bgloginbutton hover:bg-bg-bgloginbutton/90 
                        rounded-3xl text-[16px] leading-16 font-semibold font-Poppins px-6 py-2.5 text-center inline-flex items-center 
                        mr-2 mb-2" disabled={loading}> submit 
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-6 pl-2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                        </svg>
                        </button>
                        }
                    </form>
                   {showSuccessModal && (
                    <div className="mt-12 mx-4 px-4 rounded-md border-l-4 border-green-500 bg-green-50 md:max-w-2xl md:mx-auto md:px-8">
                      <div className="flex justify-between py-3">
                      <div className="flex">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 rounded-full text-green-500" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                        </svg>
                    </div>
                    <div className="self-center ml-3">
                        <span className="text-green-600 font-semibold">
                            Success
                        </span>
                        <p className="text-green-600 mt-1">
                        Health Insight created successfully
                        </p>
                    </div>
                </div>
                    <button className="self-start text-green-500" onClick={() =>  setShowSuccessModal(false)}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                        </svg>
                    </button>
                      </div>
                  </div>
                    )}
          </div>
        </div>
      </div>
         
      );
}

export default HealthInsight