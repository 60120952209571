import React from "react";
import MiniSidebar from "../../components/dashboard/MiniSidebar";
import ProfileDetails from "../../containers/userProfile/ProfileDetails";
import ProfileCards from "../../containers/userProfile/ProfileCards";

const UserProfile = () => {
  return (
    <div>
       <MiniSidebar/>
        <div className="flex md:flex-row-reverse flex-wrap pl-[7.5rem]  bg-bgnude">
            <div className = "w-full md:w-3/4 ">
          <ProfileCards/>
           </div>
              <div className = "w-full md:w-1/4 ">
                <ProfileDetails/>
        </div>
        </div>
        </div>
  )
}

export default UserProfile;
