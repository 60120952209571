import React from "react";
import { activityData} from "../../services/data/activitiesData";
import { images } from "../../constants/icons";


const ActivitiesWidget = ({title, className, seeAll}) => {
  return (
    <div className={className}>
      <div className="flex justify-between items-center ">
        <p className="text-base font-bold"> {title}</p>
        <p><img src={images.verticalIcon} alt="" /></p>
      </div>
      <div className="py-[20px]">
        { activityData&&
           activityData.map((item, i) => (
            <div key={i} className="flex justify-between w-[100%]">
              <div className="w-[20%] mt-1">
                <img src={item.icon} alt="" />
              </div>
              <div className="w-[80%] mb-2">
                <p className="text-sm font-semibold my-1">{item.status}</p>
                <p className="text-[10px] textgray">{item.article}</p>
                <p className="text-[10px] textgray">{item.date}</p>
              </div>
            </div>
          ))}
     
      </div>
      <div className="flex justify-center font-semibold text-sm mt-1"><p>{seeAll}</p></div>
    </div>
  );
};

export default ActivitiesWidget;
