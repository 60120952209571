import React from "react";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Dashboard from "../pages/dashboard/SuperAdminDashboard";
import AdminDashboard from "../pages/admin-dashboard/AdminDashboard";
import CorpoporateAdmin from "../pages/corporate-dashboard/CorpoporateAdmin";
import CorporateAdminDashboard from "../pages/corporate-dashboard/CorporateAdminDashboard";
import HealthWorkerDashboard from "../pages/health-worker-dashboard/HealthWorkerDashboard";
import ClientDashborad from "../pages/client-dashboard/ClientDashborad";
import MmbLogin from "../pages/super-admin-login/MmbLogin"
import PhrDashboard from "../pages/phr-dashboard/PhrDashboard";
import SuperAdminLandingPage from "../pages/dashboard/SuperAdminLandingPage";
import UserProfile from "../pages/user-profile/UserProfile";
import { ProtectedRoute } from "../utils/ProtectedRoute";
import { useAuth } from "../authProvider";
import AllUsers from "../pages/users/AllUsers";
import BroadcastMessage from "../pages/broadcast/BroadcastMessage";
import HealthInsight from "../pages/Insight/HealthInsight";



const RoutesManager = () => {
  const {token} = useAuth

  const routesForAuthenticatedOnly = [
    {
      path: "/",
      element: <ProtectedRoute />, // Wrap the component in ProtectedRoute
      children: [
        { path: "/", element: <SuperAdminLandingPage/>},
        { path: "/services/phr", element: <PhrDashboard/>},
        { path: "/user-profile", element: <UserProfile/>},
        { path: "/corporate-landing-page", element: <CorpoporateAdmin/>},
        { path: "/corporate-admin-dashboard", element: <CorporateAdminDashboard/>},
        { path: "/health-worker-dashboard", element: <HealthWorkerDashboard/>},
        { path: "/all-users/user-profile/:id", element: <UserProfile/>},
        { path: "/corporate-landing-page", element: <CorpoporateAdmin/>},
        { path: "/super-admin-dashboard", element: <Dashboard/>},
        { path: "/client-dashboard", element: <ClientDashborad/>},
        { path: "/admin-dashboard", element: <AdminDashboard/>},
        { path: "/broadcast", element: <BroadcastMessage/>},
        { path: "/all-users", element: <AllUsers/>},
        { path: "/Health-insight", element: <HealthInsight/>},

      ],
    },
  ];

   // Define routes accessible only to non-authenticated users
   const routesForNotAuthenticatedOnly = [
    {
      path: "/login",
      element: <MmbLogin/>
    },
  ];

    // Combine and conditionally include routes based on authentication status
    const router = createBrowserRouter([
      ...(!token ? routesForNotAuthenticatedOnly : []),
      ...routesForAuthenticatedOnly,
    ]);

      // Provide the router configuration using RouterProvider
  return <RouterProvider router={router} />;
};

export default RoutesManager;
