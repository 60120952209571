import React from "react";
import { userDataIcons } from "../../../constants/icons";

const Index = ({ img }) => {
  return (
    <div className="flex justify-between w-[210px] h-[188px]  bg-white rounded-lg p-[20px]">      <div>
        <p className="w-[66px]">
          <img src={img} alt="" className="w-[100%] mb-[15px]" />
        </p>
        <p className="text-[12px]">Health Workers</p>
        <p className="text-[24px] font-bold">570</p>
      </div>

      <div className="flex justify-between mt-[70%]">
        <p className="pt-3">
          <img src={userDataIcons.upArrowIcon} alt="" />
        </p>

        <p className="font-medium">65</p>
      </div>
      <div className="flex justify-between mt-[70%]">
        <p className="pt-2">
          <img src={userDataIcons.downArrowIcon} alt="" />
        </p>
        <p className="font-medium">65</p>
      </div>
    </div>
  );
};

export default Index;
