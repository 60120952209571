import { useState, useEffect, useRef } from "react";
import { logo } from "../../constants/images";
import { MiniSidebarData } from "../../services/data/miniSideBarData";

const MiniSidebar = () => {

    const profileRef = useRef()

    const [isProfileActive, setIsProfileActive] = useState(false)

    useEffect(() => {
        const handleProfile = (e) => {
            if (profileRef.current && !profileRef.current.contains(e.target)) setIsProfileActive(false)
        }
        document.addEventListener('click', handleProfile)
    }, [])

    return (
        <>
            <nav
                className="fixed top-0 left-0 w-[77px] py-[11px]  h-full border-r bg-white space-y-8">
                <div class="flex flex-col h-full">
                    <div className='h-20 flex items-center justify-center px-8'>
                        <a href='javascript:void(0)' className='flex-none'>
                            <img src={logo.mmblogo2} width={75} className="mx-auto py-[30px]" alt=""/>
                        </a>
                    </div>
                    <div className="flex-1 flex flex-col h-full">
                        <ul className="px-4 text-sm font-medium flex-1">
                            {
                                MiniSidebarData.map((item, idx) => (
                                    <li key={idx}>
                                        <a href={item.href} className="relative flex items-center justify-center gap-x-2 py-4  p-2 rounded-lg   ">
                                            <div className="text-gray-500">{item.icon}</div>
                                        </a>
                                    </li>
                                ))
                            }
                        </ul>
                        <div>
                            
                        </div>
                    </div >
                </div>
            </nav>
        </>
    );
};

export default MiniSidebar;