import React from "react";
import { incomeImg } from "../../constants/images";
import {serviceCorpIcons, userDataIcons} from "../../constants/icons"

const IncomeCard = () => {
  return (
    <div className="flex justify-between bg-white rounded-lg w-[240px] h-[188px] pt-[41.3px] px-[13.93px]">
      <div className="pr-3">
        <div className="flex justify-between">
          <p>
            <img src={userDataIcons.incomeArrow} alt="" />
          </p>
          <div>
            <p className="text-[10px] font-semibold text-textgray">Income</p>
            <p className="text-[13px] font-medium">#1,000,000</p>
          </div>
        </div>

        <p className="mt-[48.42px]  ">
          <img src={incomeImg.greenLine} alt="" />
        </p>
      </div>

      <div className="pt-[25px]">
        <img src={serviceCorpIcons.seperator} alt="" />
      </div>
      <div className="pl-3">
        <div className="flex justify-between">
          <p>
            <img src={userDataIcons.outcomeArrow} alt="" />
          </p>
          <div>
            <p className="text-[10px] font-semibold text-textgray">Outcome</p>
            
        <p className="text-[13px] font-medium">#200,000</p>
          </div>
        </div>

        <p className="mt-[48.42px] ">
          <img src={incomeImg.redLine} alt="" className=""/>
        </p>
      </div>

      <div>

       
      </div>
    </div>
  );
};

export default IncomeCard;
