import React from "react";

const ClientBooking = ({ type, number, icons }) => {
  return (
    <div className="bg-white flex justify-between w-[220px] h-[130px] rounded-lg px-[20px] py-[10px] items-center">
      {" "}
      <div className="w-[129px]">
        <p className="text-[12px] text-textgray">{type}</p>
        <p className="text-[24px] font-bold">{number}</p>
      </div>
      <div>
        <img src={icons} alt="" />
      </div>
    </div>
  );
};

export default ClientBooking;
