import React from 'react'
import ActivitiesWidget from '../../components/dashboard/ActivitiesWidget'
import MsgWidget from '../../components/dashboard/MsgWidget'

const DashboardAsideContainer = () => {
  return (
    <div >
        <div className="h-[500px]">
           <ActivitiesWidget title={'Recent Activities'} className={"bg-white h-[500px] rounded-lg p-[20px] font-Montserrat"} seeAll={'see all'}/>
        </div>
        <div className='mt-5'>
        <MsgWidget className={"mt-4 bg-white rounded-lg p-3  font-Montserrat"} seeAll={'see all'}/>
        </div>
    </div>
  )
}

export default DashboardAsideContainer