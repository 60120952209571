import React from "react";
import FilterContainer from "../../components/global/SearchInput";

const AllUserSearchContainer = () => {
  return (
    <div className="flex justify-between ">
      <FilterContainer />
      <div className="flex justify-between w-[20%]">
        <div className="bg-[#47A7FD] px-12 py-2 rounded-lg">
          <button>
            <p className="text-white">Filter</p>
          </button>
        </div>
        <p className="pt-2">
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.15576 7.13333H20.5246C21.3386 7.13333 22.1192 6.82821 22.6948 6.2851C23.2704 5.742 23.5937 5.00539 23.5937 4.23732C23.5937 3.46925 23.2704 2.73264 22.6948 2.18953C22.1192 1.64642 21.3386 1.34131 20.5246 1.34131H4.15576C3.34177 1.34131 2.56112 1.64642 1.98554 2.18953C1.40997 2.73264 1.08661 3.46925 1.08661 4.23732C1.08661 5.00539 1.40997 5.742 1.98554 6.2851C2.56112 6.82821 3.34177 7.13333 4.15576 7.13333Z"
              fill="#D7D7D7"
            />
            <path
              d="M20.5246 9.06396H4.15576C3.34177 9.06396 2.56112 9.36908 1.98554 9.91219C1.40997 10.4553 1.08661 11.1919 1.08661 11.96C1.08661 12.728 1.40997 13.4647 1.98554 14.0078C2.56112 14.5509 3.34177 14.856 4.15576 14.856H20.5246C21.3386 14.856 22.1192 14.5509 22.6948 14.0078C23.2704 13.4647 23.5937 12.728 23.5937 11.96C23.5937 11.1919 23.2704 10.4553 22.6948 9.91219C22.1192 9.36908 21.3386 9.06396 20.5246 9.06396Z"
              fill="#D7D7D7"
            />
            <path
              d="M20.5246 16.7866H4.15576C3.34177 16.7866 2.56112 17.0917 1.98554 17.6348C1.40997 18.1779 1.08661 18.9146 1.08661 19.6826C1.08661 20.4507 1.40997 21.1873 1.98554 21.7304C2.56112 22.2735 3.34177 22.5786 4.15576 22.5786H20.5246C21.3386 22.5786 22.1192 22.2735 22.6948 21.7304C23.2704 21.1873 23.5937 20.4507 23.5937 19.6826C23.5937 18.9146 23.2704 18.1779 22.6948 17.6348C22.1192 17.0917 21.3386 16.7866 20.5246 16.7866Z"
              fill="#D7D7D7"
            />
          </svg>
        </p>
        <p className="pt-2">
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.63102 1.34131H4.53882C2.84377 1.34131 1.46967 2.6379 1.46967 4.23732V8.09866C1.46967 9.69808 2.84377 10.9947 4.53882 10.9947H8.63102C10.3261 10.9947 11.7002 9.69808 11.7002 8.09866V4.23732C11.7002 2.6379 10.3261 1.34131 8.63102 1.34131Z"
              fill="#1D3573"
            />
            <path
              d="M20.9076 1.34131H16.8154C15.1204 1.34131 13.7463 2.6379 13.7463 4.23732V8.09866C13.7463 9.69808 15.1204 10.9947 16.8154 10.9947H20.9076C22.6027 10.9947 23.9768 9.69808 23.9768 8.09866V4.23732C23.9768 2.6379 22.6027 1.34131 20.9076 1.34131Z"
              fill="#1D3573"
            />
            <path
              d="M8.63102 12.9253H4.53882C2.84377 12.9253 1.46967 14.2219 1.46967 15.8213V19.6826C1.46967 21.2821 2.84377 22.5787 4.53882 22.5787H8.63102C10.3261 22.5787 11.7002 21.2821 11.7002 19.6826V15.8213C11.7002 14.2219 10.3261 12.9253 8.63102 12.9253Z"
              fill="#1D3573"
            />
            <path
              d="M20.9076 12.9253H16.8154C15.1204 12.9253 13.7463 14.2219 13.7463 15.8213V19.6826C13.7463 21.2821 15.1204 22.5787 16.8154 22.5787H20.9076C22.6027 22.5787 23.9768 21.2821 23.9768 19.6826V15.8213C23.9768 14.2219 22.6027 12.9253 20.9076 12.9253Z"
              fill="#1D3573"
            />
          </svg>
        </p>
      </div>
    </div>
  );
};

export default AllUserSearchContainer;
