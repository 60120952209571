import RoutesManager from "./routesManager/RoutesManager";
import { BrowserRouter } from "react-router-dom";
import AuthProvider from "./authProvider";


function App() {
  return (
    <AuthProvider>
    {/* <BrowserRouter> */}
      <RoutesManager />
    {/* </BrowserRouter> */}
    </AuthProvider>
  );
}

export default App;
