import powerIcon from "../global/icons/power.svg";
import nofityIcon from "../global/icons/notification.svg";
import searchIcon from "../global/icons/search.svg";
import verticalIcon from "../global/icons/vertical.svg";
import lineIcon from "../global/icons/lineIcon.svg";
import greenIcon from "../global/icons/green.svg";
import blueIcon from "../global/icons/blue.svg";
import orangeIcon from "../global/icons/orangle.svg";
import purpleIcon from "../global/icons/purple.svg";
import redIcon from "../global/icons/red.svg";
import yellowIcon from "../global/icons/yellow.svg";
import settingIcon from "../global/icons/settings.svg";
import hardDriveIcon from "../global/icons/harddrive.svg";
import creditCardIcon from "../global/icons/creditCard.svg";
import sendIcon from "../global/icons/send.svg";
import briefCaseIcon from "../global/icons/briefcase.svg";
import usersIcon from "../global/icons/users.svg";
import dashboardIcon from "../global/icons/dashboard.svg";
import horizontalIcon from "../global/icons/horizontal.svg";
import creditIcon from "../global/icons/creditcardArt.svg";
import harddriveIcon from "../global/icons/hardDriveArt.svg";
import creditGreenIcon from "../global/icons/creditCardgrnArt.svg";
import userIcon from "../global/icons/userArt.svg";
import profile from '../global/icons/adminPic.svg';
import dropdown from '../global/icons/chevron-down.svg';
import helpcenter from '../global/icons/helpcenter.svg';
import jobsIcon from '../global/icons/jobs.svg';
import seperator from '../global/icons/seperator.svg';
import userCorpIcon from '../global/icons/greenuser.svg';
import housePurCorpIcon from '../global/icons/purplehouse.svg';
import mobileCorpIcon from '../global/icons/mobile.svg';
import houseBlueCorp from '../global/icons/bluehouse.svg';
import clientIcon from '../global/icons/client.svg';
import workerIcon from '../global/icons/worker.svg';
import downArrowIcon from '../global/icons/downCorpIcon.svg';
import upArrowIcon from '../global/icons/upCorpIcon.svg';
import incomeArrow from '../global/icons/incomeArrow.svg';
import outcomeArrow from '../global/icons/outcomeArrow.svg';
import dropdownIcon from '../global/icons/dropdownIcon.svg';
import greenIndiIcon from '../global/icons/greenIndicator.svg';
import yellowIndiIcon from '../global/icons/yelowIndicator.svg';
import clientSuccessIcon from '../global/icons/clientBookingSucess.svg';
import clientTotalBookingIcon from '../global/icons/clientTotalBooking.svg';
import clientCancelIcon from '../global/icons/clientBookingCancel.svg';
import home  from '../global/icons/Hom.svg';
import Calendar from '../global/icons/Calendar.svg';
import Dialogues from '../global/icons/Dialogue.svg';
import Loupe from '../global/icons/Loupe.svg';
import Settings from '../global/icons/Setting.svg';
import user from '../global/icons/user.svg';
import Ellipse from '../global/icons/Ellipse 90.svg';
import bank from '../global/icons/bank.svg';
import savings from '../global/icons/savings.svg';
import wallet from '../global/icons/wallet.svg';
import box from '../global/icons/box.svg';
import dot from '../global/icons/dot.svg';
import graph from '../global/icons/graph.svg';
import arrowDown from '../global/icons/arrowDown.svg'



export const images = {
  powerIcon,
  nofityIcon,
  searchIcon,
  verticalIcon,
  lineIcon,
  greenIcon,
  orangeIcon,
  purpleIcon,
  yellowIcon,
  redIcon,
  blueIcon,
  horizontalIcon,
  userIcon
};

export const sideIcons = {
  briefCaseIcon,
  dashboardIcon,
  settingIcon,
  hardDriveIcon,
  creditCardIcon,
  sendIcon,
  usersIcon,
  profile,
  dropdown,
  helpcenter
};

export const ActivitiesIcon = {creditGreenIcon, harddriveIcon,userIcon, creditIcon };

export const serviceCorpIcons = {jobsIcon,seperator,userCorpIcon,houseBlueCorp,housePurCorpIcon, mobileCorpIcon}


export const userDataIcons = {clientIcon, workerIcon,downArrowIcon, upArrowIcon,incomeArrow,outcomeArrow}

export const appointmentDataIcons ={dropdownIcon}

export const healthWorkerIcons = {greenIndiIcon, yellowIndiIcon}

export const clientIcons = {clientCancelIcon, clientTotalBookingIcon, clientSuccessIcon}

export const minibar = {home, usersIcon, Calendar, Dialogues, Loupe, Settings, user, Ellipse, bank, savings, wallet,box,dot,graph,arrowDown }