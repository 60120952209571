import React from "react";
import { images } from "../../constants/icons";

const OverviewWidget = ({
  title,
  numbers,
  group,
  middle,
  bottom,
  topNum,
  middleNum,
  bottomNum,
}) => {
  return (
    <div className="bg-white py-[30px] px-[20px] lg:w-[339px] lg:h-[235px] rounded-lg max-[650px]:w-[100%]">
      <div className="flex justify-between items-center">
        <p className=" text-lg font-bold">Overview</p>
        <p>
          <img src={images.verticalIcon} alt="" />
        </p>
      </div>
      <p className="text-xs my-1 font-Montserrat text-textgray">{title}</p>
      <div className="flex justify-between mt-6 items-center ">
        <div>
          <p className="font-bold text-3xl"> {numbers}</p>
          <p className="text-sm font-medium">{group}</p>
        </div>
        <p className="items-center">
          <img src={images.lineIcon} alt="" />
        </p>

        <div className="text-[13px]">
          <div className="flex space-x-2 items-center">
            <p className="">
              <img src={images.yellowIcon} alt="" />
            </p>{" "}
            <p>New</p>
          </div>
          <div className="flex space-x-2 items-center">
            {" "}
            <p className="">
              <img src={images.greenIcon} alt="" />
            </p>{" "}
            <p>{middle}</p>{" "}
          </div>
          <div className="flex space-x-2 items-center ">
            {" "}
            <p className="">
              <img src={images.redIcon} alt="" />
            </p>{" "}
            <p>{bottom}</p>{" "}
          </div>
        </div>
        <div className="text-[16px] font-semibold">
          <p>{topNum}</p>
          <p>{middleNum}</p>
          <p>{bottomNum}</p>
        </div>
      </div>
    </div>
  );
};

export default OverviewWidget;
