import React from 'react'
import PaymentHealthContainer from '../paymentHealthContainer/PaymentHealthContainer'
import ConsultationHealthWorker from '../../components/healthWorker/ConsultationHealthWorker'


const breakdownContainer = () => {
  return (
    <div className="flex justify-between mb-[15px]">
    <div>
      <ConsultationHealthWorker/>
    </div>
    <div>
         <PaymentHealthContainer/>
    </div>
    </div>
   
  )
}

export default breakdownContainer
