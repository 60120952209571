import React from "react";
import {
  appointmentHeader,
  appointmentData,
} from "../../services/data/corporateData";
import { appointmentDataIcons } from "../../constants/icons";

const AppointmentTable = ({className}) => {
  return (
    <div className={className}>
      <div className="bg-white flex justify-between p-[20px] rounded-t-[8px]">
        <p className="font-semibold text-[16px]">Appointment</p>
        <button
          onClick={() => alert("display")}
          className="bg-[#F2F2F2] w-[148px] p-[10px] rounded-lg flex justify-between "
        >
          <p className="text-[10px] font-medium"> Latest Appointment</p>
          <p className="mt-2 mr-2">
            <img src={appointmentDataIcons.dropdownIcon} alt="" />
          </p>
        </button>
      </div>

      <table className="items-center">
        <thead className="">
          <tr className="whitespace-nowrap pl-3">
            {appointmentHeader &&
              appointmentHeader.map((item, idx) => (
                <td
                  key={idx}
                  className="text-[13px] py-[7px]  w-[200.757px] font-medium  whitespace-nowrap"
                >
                  {item.title}
                </td>
              ))}
          </tr>
        </thead>
        <tbody className=" bg-white ">
          {appointmentData &&
            appointmentData.map((item, idx) => (
              <tr className="whitespace-nowrap" key={idx}>
                <td className="w-[170.757px] pl-[17px]">
                  <div className="flex items-center space-x-2 py-2">
                    <div className="w-[30px]">
                      <img src={item.image} alt="" className="w-[100%]" />
                    </div>
                    <div>
                      <p className="text-[13px] font-medium">{item.name}</p>
                      <p className="text-[10px]">{item.service}</p>
                    </div>
                  </div>
                </td>
                <td className="text-[10px] font-medium ">{item.id}</td>
                <td className="text-[10px] font-medium ">{item.amount}</td>
                <td className="text-[10px] font-medium ">{item.startDate}</td>
                <td className="text-[10px] font-medium ">{item.endDate}</td>
                <td className="text-[10px] font-medium ">
                  <div>
                    <div className="flex justify-between">
                      <p>{item.percentage}</p>
                      <p>{item.status}</p>
                    </div>
                    <div class="mb-6 h-1 w-full bg-neutral-200 dark:bg-neutral-600">
                      <div
                        class="h-1 "
                        style={{
                          width: `${item.percentage}`,
                          backgroundColor: `${item.bg}`,
                        }}
                      ></div>
                    </div>
                  </div>
                </td>
                <td className="pl-6">
                  <img src={item.icon} alt="" />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default AppointmentTable;
