import React, { useState } from "react";
import CorporateSidebar from "../../components/corporatedashboard/CorporateSidebar";
import CorporateHeader from "../../components/corporatedashboard/CorporateHeader";
import CardsContainer from "../../containers/corporateContainer/CardsContainer";
import CorporateLoadingBlock from "../../components/corporatedashboard/CorporateLoadingBlock";

export const delQuery = (asPath) => {
  return asPath.split("?")[0];
};

const CorpoporateAdmin = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div className="flex justify-between bg-[#F9F9F9]  font-Montserrat">
      <CorporateSidebar isOpen={isOpen} setOpen={setOpen} />
      <div className="w-[calc(100%-212px)] xl:w-[calc(100%-212px)]  pl-[36px] pt-[22.5px] pr-[36px]">
        <CorporateHeader isOpen={isOpen} setOpen={setOpen} />
        <CardsContainer />
        <div className="flex flex-wrap w-[100%] gap-3 mt-[70px]">
          {[1, 2, 3, 4, 5, 6, 7, 8].map((card) => (
            <CorporateLoadingBlock key={card} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CorpoporateAdmin;
