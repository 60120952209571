import React, { useState } from "react";
import AcountInfo from "../../components/corporatedashboard/AcountInfo";
import CorporateHeader from "../../components/corporatedashboard/CorporateHeader";
// import UserSidebar from "../../components/global/UserSidebar";
import AppointmentTable from "../../components/corporatedashboard/AppointmentTable";
import ScheduleContainer from "../../containers/corporateContainer/ScheduleContainer";
import ActivitiesHealthContainer from "../../containers/healthWorkersContainer/ActivitiesHealthContainer";
import BreakdownContainer from "../../containers/healthWorkersContainer/BreakdownContainer";
import ClientSidebar from "../../components/global/ClientSidebar";
import HealthWorkerID from "../../components/healthWorker/HealthWorkerID";

export const delQuery = (asPath) => {
  return asPath.split("?")[0];
};

const HealthWorkerDashboard = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div className="flex justify-between bg-[#F9F9F9]  font-Montserrat">
      <ClientSidebar isOpen={isOpen} setOpen={setOpen} statusStyle={'mt-[100vh]'}/>
      <div className="w-[calc(100%-212px)] xl:w-[calc(100%-212px)]  pl-[36px] pt-[22.5px] pr-[15px]">
        <CorporateHeader isOpen={isOpen} setOpen={setOpen} />

        <>
          <div className="flex justify-between w-[100%]">
            <div className="mr-[15px]">
              <div className="mb-[74px]">
                <AcountInfo />
              </div>

              <BreakdownContainer />
              <ActivitiesHealthContainer />
              <AppointmentTable className={"w-[100%] mt-[30px]"} />
            </div>
            <div>
              <HealthWorkerID />
              <ScheduleContainer />
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default HealthWorkerDashboard;
