import React from "react";
import ClientBooking from "../../components/client/ClientBooking";
import { clientIcons } from "../../constants/icons";

const ClientBookingContainer = () => {
  return (
    <div className="flex justify-between w-[100%]">
      <div>
        <ClientBooking  type={"Total Booking"} number={1000} icons={clientIcons.clientTotalBookingIcon}/>
      </div>
      <div>
        <ClientBooking  type={"Booking Success"} number={300} icons={clientIcons.clientSuccessIcon}/>
      </div>
      <div>
        <ClientBooking  type={"Booking cancel"} number={520}  icons={clientIcons.clientCancelIcon}/>
      </div>
    </div>
  );
};

export default ClientBookingContainer;
