
import { ActivitiesIcon} from "../../constants/icons"
import { msgImages } from "../../constants/images"

export const activityData = [
    {
        id: 1,
        date: "Just now",
        status: "Payment Approved",
        article:'Dr. Adebusoye withdrawal request has been approved by Vivian Aare',
        icon: ActivitiesIcon.creditGreenIcon,
      },
      {
        id: 2,
        date: "15 mins ago",
        status: "Active",
        article:'Luth Hospital account as been verified by Vivian Aare.',
        icon: ActivitiesIcon.userIcon,
      
      },
      {
        id: 3,
        date: "15 mins ago",
        status: "Pending",
        article:'MMB created a new services that requires verification.',
        icon: ActivitiesIcon.harddriveIcon,
      },
      {
        id: 4,
        date: "Yesterday",
        status: "Active",
        article:'RedCare  request to withdraw #50,000.',
        icon: ActivitiesIcon.creditIcon,
      },
];



export const messaging = [
  {
    name:'Olarenwaju Saheed',
    image:msgImages.saheedImg,
    mssg:'There are many variations '
  },
  {
    name:'Cameron Williamson',
    image:msgImages.cameronImg,
    mssg:'There are many variations '
  },
  {
    name:'Leslie Alexander',
    image:msgImages.alexlieImg,
    mssg:'There are many variations '
  },
  {
    name:'Guy Hawkins',
    image:msgImages.hawkingsGImg,
    mssg:'There are many variations '
  },
  {
    name:'Jacob Jones',
    image:msgImages.jonesImg,
    mssg:'There are many variations '
  },

]