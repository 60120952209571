import React, { useState } from "react";
import DashboardHeader from "../../components/dashboard/DashboardHeader";
import DashboardContainer from "../../containers/dashboardContainer/DashboardContainer";
import DashboardAsideContainer from "../../containers/dashboardContainer/DashboardAsideContainer";
// import AdminSidebar from "../../components/admindashboard/AdminSidebar";
import Sidebar from "../../components/dashboard/Sidebar";

export const delQuery = (asPath) => {
  return asPath.split("?")[0];
};

const AdminDashboard = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <div className="flex justify-between bg-[#F9F9F9]  font-Montserrat">
      <Sidebar isOpen={isOpen} setOpen={setOpen} />
      <div className="w-[calc(100%-212px)] xl:w-[calc(100%-212px)]  pl-[33px] pt-[14px] pr-[59px]">
        <DashboardHeader isOpen={isOpen} setOpen={setOpen} />

        <main className="line-clamp-1 lg:pt-[25px]    max-lg:container  rounded-xl min-h-[100vh]  xl:pt-[25px] flex justify-between max-xsm:flex-col">
          <div className="w-[calc(100%-230px)] mr-[18px] max-[550px]:w-[100%]">
            <DashboardContainer />
          </div>
          <div className="w-[230px] max-[550px]:w-[100%]">
            <DashboardAsideContainer />
          </div>
        </main>
      </div>
    </div>
  );
};

export default AdminDashboard;
