import {healthWorkerImages} from "../../constants/images"

export const workerId = [
    {
      name: "Esther Howard",
      image: healthWorkerImages.estherImage,
      id: "PT-123456",
      detail:"Personal Detail",
      personalDetails: [
        {
          title: "Location:",
          value: "Victoria Island",
          type: "Account Type",
          profession: "Nurse",
          status: "Profession",
          degree: "RedCare HMO",
        },
      ],
    },
  ];
  