import React from "react";
import { serviceData } from "../../services/data/corporateData";

const ServicesOverview = () => {
  return (
    <div className="bg-white w-[96%]  rounded-lg p-[20px]">
      <p className="font-semibold">Services Overview</p>

      <div className="flex justify-between items-center">
        {serviceData &&
          serviceData.map((item) => (
            <div className=" px-[20px] py-[10px] ">
              <p className="w-[50px]">
                <img src={item.logo} alt="" className="w-[100%] mb-3 " />
              </p>
              <p className="text-xl font-bold">{item.numbers}</p>
              <p className="text-textgray text-[10px]">{item.service}</p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ServicesOverview;
