import React from "react";
import { PieChart, Pie,  Cell } from "recharts";



const Index = () => {
  const data = [
    { name: "Group A", value: 300 },
    { name: "Group B", value: 300 },
    { name: "Group C", value: 100 },
  
  ];
  const COLORS = ["#DB3F40", "#47A7FD", "#F2F2F2"];
  return (
    <PieChart width={100} height={100}>
      <Pie
        data={data}
        // cx={100}
        // cy={100}
        innerRadius={30}
        outerRadius={50}
        fill="#8884d8"
        // paddingAngle={1}
        dataKey="value"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
  
    </PieChart>
  );
}

export default Index;
