import React from 'react'
import Schedule from '../../components/corporatedashboard/Schedule'
import Calendar from 'react-calendar'
import { images } from '../../constants/icons'

const ScheduleContainer = () => {
  return (
    <div className="bg-white rounded-lg p-[20px] w-[318px]">
        <div className="flex justify-between">
            <p className="text-[18px] font-semibold">Your Schedule</p>
            <p className="mt-1"><img src={images.verticalIcon} alt="" /></p>
        </div>
        <Calendar/>
        <Schedule/>

        <div className="flex justify-between text-[10px] mt-[40.5px]">
          <p className="text-textgray">5 cases today</p>
          <p>View more</p>

        </div>
    </div>
  )
}

export default ScheduleContainer