import React from "react";
import { msgImages } from "../../constants/images";

const Schedule = () => {
  return (
    <div>
      {[1, 2, 3, 4].map((item) => (
        <div className="flex justify-between w-[100%] my-3 bg-white items-center " key={item}>
          <div className="text-[9px]">
            <p>8:00 AM</p>
            <p>8:00 AM</p>
          </div>
          <div className="">
            <input type="radio" />
          </div>
          <div className=" flex justify-between bg-[#F2F2F2] w-[208px] rounded-lg p-[10px]">
            <div>
              <img src={msgImages.alexlieImg} alt="" />
            </div>
            <div>
              <p className="text-[14px]">Weston James </p>
              <p className="text-[10px] text-textgray">Consultation</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Schedule;
