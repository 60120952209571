import React from "react";
import GoodmorningCard from "../../components/corporatedashboard/GoodmorningCard";
import CorporateCards from "../../components/corporatedashboard/CorporateCards";

const CardsContainer = () => {
  return (
    <div>
      <GoodmorningCard />

      <div className="absolute top-[190px]  right-[40px]">
        <CorporateCards />
      </div>
    </div>
  );
};

export default CardsContainer;
