import React from "react";
import { corporateDisplayCards } from "../../services/data/corporateData";

const CorporateCards = () => {
  return (
    <div className="[&>*:nth-child(1)]:bg-[#F2DBB1] [&>*:nth-child(1)]:rounded-lg [&>*:nth-child(1)]:pt-[53px] [&>*:nth-child(2)]:bg-[#679AFF] [&>*:nth-child(2)]:pt-[33px] [&>*:nth-child(2)]:rounded-lg [&>*:nth-child(3)]:bg-[#97D482] [&>*:nth-child(3)]:w-[173px] [&>*:nth-child(3)]:pt-[33px] [&>*:nth-child(3)]:rounded-l-[10px] flex justify-between w-[650px]">
      {corporateDisplayCards &&
        corporateDisplayCards.map((card, idx) => (
          <div key={idx}
            className="w-[227px] h-[295px] pl-[11px]  text-white">
            <div className="w-[143px]">
              <p className="text-xl font-semibold ">{card.title}</p>
              <p className="font-medium text-[10px]">{card.description} </p>
            </div>

            <div>
              <img src={card.img} alt="" className="" />
            </div>
          </div>
        ))}
    </div>
  );
};

export default CorporateCards;
