import React from "react";

const AllUserCard = ({key, first_name,last_name,imageUrl,username,id
}) => {
  // console.log(id)
  return (
    <div class="w-[25%] h-[157.35px] bg-white rounded-[20px] shadow flex justify-between pr-[10px] pt-[28px] pl-[25px]" key={key}>
      <div class="w-[97.07px] h-[86.88px] relative">
        {imageUrl === null   ||imageUrl === '' ? <div class="w-[92.07px] h-[86.88px] left-0 top-0 absolute bg-stone-300 rounded-[10px]"></div>: <img src={imageUrl} alt="" /> }
        <div class="w-[25.58px] h-[24.13px] left-[71.50px] top-[62.75px] absolute bg-red-600 rounded-full border-4 border-white"></div>
      </div>
      <div className="w-[50%]">
        <p className="font-[10px] break-words">{first_name || "first name" 
} {last_name || 'last name'} </p>
        <p className="text-[#717579] break-words">{username || "username"
}</p>
        <p className="text-[#1D3573]"><a href={`/all-users/user-profile/${id}`}>View profile </a></p>
      </div>

      
    </div>
  );
};

export default AllUserCard;
