import { phrImages } from "../../constants/images" 

export const phrData = [
   
    {
        label: "phr-dashboard",
        img:phrImages.phrImg,
        to: "/services/phr",
      },
    {
        label: "home-care",
        img:phrImages.phrhomecareImg,
        to: "/",
      },
    {
        label: "health-workers",
        img:phrImages.phrhelpcenterImg,
        to: "/",
      },

]