import React from 'react'

const AcountInfo = () => {
  const name = 'John'
  return (
    <div className="font-Montserrat">
        <p className="text-[30px]">Good Evening {name},</p>
        <p className="text-[12px] text-textgray">Check the latest updates on your account.</p>
    </div>
  )
}

export default AcountInfo