import React from "react";
import {
  LineChart,
  Line,
  // CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";

const TwoLinesChart = () => {
  const data = [
    {
      name: "Mon",
      Bk: 35,
      Cl: 4,
    
    },
    {
      name: "Tue",
      Bk: 30,
      Cl: 3,
      
    },
    {
      name: "Wed",
      Bk: 20,
      Cl: 24,
    
    },
    {
      name: "Thurs",
      Bk: 27,
      Cl: 3,
    
    },
    {
      name: "Fri",
      Bk: 18,
      Cl: 16,
      
    },
    {
      name: "Sat",
      Bk: 23,
      Cl: 23,
     
    },
    {
      name: "Sun",
      Bk: 34,
      Cl: 4,
  
    },
  ];
  return (
    <div className="bg-white rounded-lg w-[271px]  h-[188px]">  
      <div className="">
      <ResponsiveContainer width="100%" aspect={2 / 1}>
        <LineChart
              width={200}
          height={180}
          data={data}
          margin={{ top: 10, right:1, left: 0, bottom: 0}}
        >
          {/* <CartesianGrid strokeDasharray="1 1" /> */}
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="Cl"
            stroke="#47A7FD"
            // activeDot={{ r: 1}}
          />
          <Line type="monotone" dataKey="Bk" stroke="#DB3F40" />
        </LineChart>
        </ResponsiveContainer>
        
     
      </div>
    </div>
  );
};

export default TwoLinesChart
