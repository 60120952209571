import React from "react";
import { images } from "../../constants/icons";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";

const Linechart = () => {
  const data = [
    {
      name: "Jan",
      Bk: 4000,
      Cl: 4400,
      In: 4400,
      amt: 2400,
    },
    {
      name: "Feb",
      Bk: 3000,
      Cl: 3300,
      In: 1398,
      amt: 2210,
    },
    {
      name: "Mar",
      Bk: 2000,
      Cl: 2400,
      In: 2800,
      amt: 2290,
    },
    {
      name: "Apr",
      Bk: 2780,
      Cl: 3480,
      In: 3908,
      amt: 2000,
    },
    {
      name: "May",
      Bk: 1890,
      Cl: 1690,
      In: 1800,
      amt: 2181,
    },
    {
      name: "Jun",
      Bk: 2390,
      Cl: 2390,
      In: 2800,
      amt: 2500,
    },
    {
      name: "July",
      Bk: 3490,
      Cl: 4342,
      In: 4300,
      amt: 2100,
    },
  ];
  return (
    <div className="bg-white  ml-5 rounded-lg h-[500px]">
      <div className="pt-4 pb-5 px-4 flex justify-between items-center ">
        <p className="text-lg font-bold ">Report</p>
        <p>
          <img src={images.verticalIcon} alt="" />
        </p>
      </div>

      <div className="mt-20">
      <ResponsiveContainer width="100%" aspect={2 / 1}>
        <LineChart
          width={350}
          height={320}
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="Cl"
            stroke="#47A7FD"
            activeDot={{ r: 8 }}
          />
          <Line type="monotone" dataKey="In" stroke="#4AF06F" />

          <Line type="monotone" dataKey="Bk" stroke="#DB3F40" />
        </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default Linechart;
