import React from "react";
import { images } from "../../constants/icons";
import {
  withdrawalData,
  withdrawalHeader,
} from "../../services/data/withdrawaldata";

const WithdrawalTable = () => {
  return (
    <div className="bg-white mt-5 rounded-lg py-[30px]   px-[20px] max-[650px]:w-[90%] max-[650px]:px-[0px]  max-[650px]:scrollbar">
      <div className="flex justify-between">
        <p className="text-lg font-semibold">Withdrawal</p>
        <p><img src={images.verticalIcon} alt="" /></p>
      </div>
      <div>
        <div>
          <table className="whitespace-nowrap">
            <thead>
              <tr className="whitespace-nowrap" tabindex="0">
                {withdrawalHeader.map((item) => (
                  <td className='text-[13px] w-[25%] font-medium  whitespace-nowrap'>{item.title}</td>
                ))}
              </tr>
            </thead>
            <tbody>
              {withdrawalData.map((item) => (
                <tr className="whitespace-nowrap w-[100%]">
                  <td className="w-[10p%]">
                    <div className="flex items-center space-x-2 pb-2">
                      <div className="w-[30px]"><img src={item.image} alt="" className="w-[100%]"/></div>
                      <div>
                        <p className="text-[13px] font-medium">{item.name}</p>
                        <p className="text-[10px]">{item.job}</p>
                      </div>
                    </div>
                  </td>
                  <td className="text-[10px] font-medium ">{item.dateRequested}</td>
                  <td className="text-[10px] font-medium ">{item.dateApproved}</td>
                  <td className="text-[10px] font-medium ">{item.dateDisbursed}</td>
                  <td className="pl-6"><img src={item.actions} alt="" /></td>
                 
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default WithdrawalTable;
