// src/reducers/dashboardReducer.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [{id:1, name:'micheal'},
  {id:2, name:'micheal'}],
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
  },
  addTodo: (state, action) => {
    const { id, text } = action.payload;
    state.push({ id, text, completed: false });
  },
  toggleTodo: (state, action) => {
    const todo = state.find((todo) => todo.id === action.payload);
    if (todo) {
      todo.completed = !todo.completed;
    }
  },
});

export const { addTodo, toggleTodo } = dashboardSlice.actions;
export default dashboardSlice.reducer;
