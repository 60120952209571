import React from "react";
import { clientsImages } from "../../constants/images";

const ClientBookFromHome = () => {
  return (
    <div className="py-[30px] flex justify-between w-[100%] pr-[15px] font-Montserrat pl-[50px] bg-white rounded-lg">
      <div className="mt-7" >
        <p className="text-[32px] font-bold w-[335px]">
          Get The Best Online Treatment At Home
        </p>
        <p className="text-[13px] text-textgray w-[320px]">
          Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet.
        </p>
        <button
          onClick={() => alert("Book now!")}
          className="bg-[#47A7FD] px-[35px] py-[10px] mt-[20px] rounded-lg w-[133px]"
        >
          <p className="text-white text-[12px]">Book now</p>
        </button>
      </div>
      <div className="w-[212px] h-[269px]">
        <img
          src={clientsImages.clientBookingImage}
          alt=""
          className="w-[100%]"
        />
      </div>
    </div>
  );
};

export default ClientBookFromHome;
