import React from "react";
import {useLocation } from "react-router-dom";

const GoodmorningCard = () => {

  const name = "Smith";
  const e = useLocation();

  return (
    <div className="bg-[#FFFFFF] w-[40vw] h-[461px] font-Montserrat rounded-[15px] pt-[59px] pb-[126px] pl-[19px]">
      <p className="text-[36px] font-bold w-[390px]">Good morning, {name}</p>
      <p className="text-textgray font-bold text-[10px] w-[260px] ">
        Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet
        sint. Velit officia consequat.
      </p>

      <button
        onClick={() => console.log("clicked")}
        className="bg-[#47A7FD] shadow-sm  hover:bg-white transition-all border-transparent hover:border-secondary  w-[220px] mt-[30px] h-[56px] rounded-[20px] items-center"
      >
        <p className="font-Poppins text-[15px]  hover:text-[#333] text-white">{e.pathname === "/"?
        <a href="/super-admin-dashboard">View All Organization</a> :
        "Create Organization"}</p>
      </button>
    </div>
  );
};

export default GoodmorningCard;
