import { sideIcons } from "../../constants/icons";
import {userSidebarImage} from "../../constants/images"

export const navigations = [
  {
    sectionTitle: "Main Menu",
    links: [
      {
        label: "Dashboard",
        icon: sideIcons.dashboardIcon,
        to: "/",
      },
      {
        label: "Corporate",
        icon: sideIcons.briefCaseIcon,
        to: "/corporate-admin-1",
      },
      {
        label: "Services",
        icon: sideIcons.hardDriveIcon,
        to: "/",
      },
      {
        label: "Broadcast Message",
        icon: sideIcons.sendIcon,
        to: "/broadcast",
      },
      {
        label: "Users",
        icon: sideIcons.usersIcon,
        to: "/",
      },
      {
        label: "Transactions",
        icon: sideIcons.creditCardIcon,
        to: "/",
      },

      {
        label: "Settings",
        icon: sideIcons.settingIcon,
        to: "/",
      },
      {
        label: "All users",
        icon: sideIcons.usersIcon,
        to: "/all-users",
      },
      {
        label: "Health Insight",
        icon: sideIcons.settingIcon,
        to: "/Health-insight",
      },
    ],
  },
];




export const profileDetail = {
  image: sideIcons.profile,
  name: "Aderopo Oluwasegun",
  title: "Super Admin",
  logo: sideIcons.dropdown,
};

//Corporate sidebar links

export const corporateLinks = [
  {
    label: "Dashboard",
    icon: sideIcons.dashboardIcon,
    to: "/",
  },
  {
    label: "Appointments",
    icon: sideIcons.briefCaseIcon,
    to: "/",
  },
  {
    label: "Services",
    icon: sideIcons.hardDriveIcon,
    to: "/",
  },
  {
    label: "Messages",
    icon: sideIcons.sendIcon,
    to: "/",
  },
  {
    label: "Transactions",
    icon: sideIcons.creditCardIcon,
    to: "/",
  },
  {
    label: "Help Center",
    icon: sideIcons.helpcenter,
    to: "/",
  },
  {
    label: "Health Insight",
    icon: sideIcons.settingIcon,
    to: "/",
  },
];

export const referers = {
  title: "Refer a friend",
  image: userSidebarImage.referrerImg,
};
