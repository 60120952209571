import React from 'react'
import TwoLinesChart from '../charts/TwoLinesChart'

const UserWidget = () => {
  return (
    <div>
        <TwoLinesChart/>
    </div>
  )
}

export default UserWidget