import React from "react";
import ActivitiesHealthWorker from "../../components/healthWorker/ActivitiesHealthWorker";
import MessagingHealthWorker from "../../components/healthWorker/MessagingHealthWorker";

const ActivitiesHealthContainer = () => {
  return (
    <div className="flex  justify-between ">
      <div>
        {" "}
        <ActivitiesHealthWorker />
      </div>
      <div>
        <MessagingHealthWorker />
      </div>
    </div>
  );
};

export default ActivitiesHealthContainer;
